@import "minima";

body {
  background: url(fish-tank.jpg) no-repeat center center fixed;
  background-size: cover;
}

div.wrapper {
  max-width: calc(800px - (30px * 2));
  margin-right: auto;
  margin-left: auto;
}

header.site-header {
  position: relative;
  border: none;
  margin-bottom: 0px;
  min-height: initial;
  z-index: 20;
  div.wrapper {
    background-color: rgba(100%, 100%, 100%, 0.90);

    @supports (-webkit-backdrop-filter: blur(10px)) {
      background-color: rgba(100%, 100%, 100%, 0.65);
      -webkit-backdrop-filter: blur(5px);
    }
    @supports (backdrop-filter: blur(10px)) {
      background-color: rgba(100%, 100%, 100%, 0.65);
      backdrop-filter: blur(5px);
    }

    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    border: none;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

main.page-content {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 40;
  div.wrapper {
    background-color: rgba(100%, 100%, 100%, 1.0);
    
    border-radius: 0px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);

    margin-top: 0px;

    padding-top: 30px;
    padding-bottom: 30px;

    h1 {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 20px;
      margin-bottom: 0px;
    }

    img.avatar {
      border-radius: 50%;
      width: 72px;
      margin-right: 10px;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    }

    div.container {
      padding-top: 30px;
      position: relative;
      text-align: center;
      img.icon {
        width: 48px;
        margin: auto;
        display: block;
      }

      div {
        width: 25%;
        position: relative;
        float: left;
        align-content: center;
      }
    }
  }
}

footer.site-footer {
  position: relative;
  border: none;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: initial;
  z-index: 80;
  div.wrapper {
    background-color: rgba(100%, 100%, 100%, 0.90);

    @supports (-webkit-backdrop-filter: blur(10px)) {
      background-color: rgba(100%, 100%, 100%, $translucent-opacity);
      -webkit-backdrop-filter: blur(5px);
    }
    @supports (backdrop-filter: blur(10px)) {
      background-color: rgba(100%, 100%, 100%, $translucent-opacity);
      backdrop-filter: blur(5px);
    }

    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    border: none;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-top: 15px;
  }

  ul.contact-list {
    font-size: $small-font-size;
    color: $grey-color;
  }
}

@media screen and (max-width: 830px) {
  div.wrapper {
    border-radius: 0px;
    max-width: none;
  }

  header.site-header {
    z-index: 5000;
    div.wrapper {
      border-radius: 0px;
      box-shadow: none;
    }
  }

  footer.site-footer {
    div.wrapper {
      border-radius: 0px;
    }
  }
}
